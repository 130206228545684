import Greenhouse from 'legacy/greenhouse';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore generated file
import translations from '../../../config/locales/compiled/translations-en.json';
const parsedTranslations = JSON.parse(translations);

window.Greenhouse = Greenhouse || {};
window.Greenhouse.Translation = Greenhouse.Translation || {};

window.Greenhouse.Translation = window.Greenhouse.Translation;
window.Greenhouse.Translation.translations = parsedTranslations;
